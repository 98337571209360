<div class="-mt-10 flex bg-background">
  <div class="flex w-[70vw] items-center justify-center max-lg:w-full">
    <form (ngSubmit)="loginUser()" [formGroup]="loginForm">
      <div class="flex h-screen items-center justify-center pt-28 xsC:pt-0">
        <!-- Login Container -->
        <div class="w-96 min-w-fit flex-col rounded-[4px] border bg-white px-6 py-14 shadow-md max-lg:h-96 max-lg:w-72">
          <div class="mb-8 flex justify-center">
            <img class="w-24" src="assets/img/42.png" alt="42 Logo" />
          </div>
          <div class="flex flex-col rounded-md text-sm">
            <input
              formControlName="email"
              class="rounded-[4px] border p-3 hover:border-yellow-500 hover:outline-none focus:outline-none"
              data-cy="login-email"
              type="text"
              placeholder="Username or Email id"
            />
            <div class="h-5">
              @if (email && email.invalid && email.touched) {
                <span class="mb-5 text-red-400">This email is not valid</span>
              }
            </div>

            <input
              formControlName="password"
              class="rounded-[4px] border p-3 hover:border-yellow-500 hover:outline-none focus:outline-none"
              data-cy="login-password"
              type="password"
              placeholder="Password"
            />
            <div class="h-8">
              @if (password && password.invalid && password.touched) {
                <span class="mb-5 text-red-400">Please enter a password</span>
              }
            </div>
          </div>

          <button
            [disabled]="loginForm.invalid"
            class="w-full rounded-[4px] border bg-gray-500 bg-gradient-to-r from-gray-800 p-2 text-white hover:bg-slate-400"
            data-cy="login-submit"
            type="submit"
          >
            Sign in
          </button>
        </div>
      </div>
    </form>
  </div>

  <div
    class="flex h-screen w-[30vw] flex-col items-center justify-center gap-4 bg-gray-200 shadow-lg max-lg:absolute max-lg:top-0 max-lg:h-auto max-lg:w-full max-lg:gap-0"
  >
    <div class="w-4/5">
      <div class="pb-0 max-lg:text-sm max-sm:text-xs lg:pb-4 xl:pb-8">
        <h1 class="mb-4 text-2xl font-bold max-lg:mb-1 max-lg:text-base">
          🔮 Unsere <span class="underline">Vision</span>
        </h1>

        Jeden Tag machen wir die Welt etwas digitaler, eröffnen neue Möglichkeiten und vereinfachen das Leben sowie den
        Alltag der Menschen.
      </div>
      <div class="flex justify-around pt-24 max-lg:pt-1">
        <a href="https://app.clockify.me" target="_blank">
          <img
            [routerLink]="['/my-book-listing']"
            class="m-2 -mt-[0.01rem] w-5 cursor-pointer lg:w-[4.2rem]"
            src="assets/img/clockify.png"
            alt="clockify"
          />
        </a>
        <a href="https://app.clickup.com" target="_blank">
          <img class="m-2 w-5 cursor-pointer lg:w-16" src="assets/img/clickup.png" alt="clickup" />
        </a>
        <a href="https://www.office.com/" target="_blank">
          <img class="m-2 w-6 cursor-pointer lg:w-[4.75rem]" src="assets/img/office.png" alt="office" />
        </a>
      </div>
    </div>
  </div>
</div>
