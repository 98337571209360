import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IEmployee } from '../models/employee.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private redirectUrl: string | null = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  /**
   * Logges in employee and stores data in local storage
   * @param {string} email - Email of employee.
   * @param {string} password - Password of employee.
   */
  public login(email: string, password: string) {
    return this.http
      .post(environment.baseUrl + '/auth/login', {
        email: email,
        password: password,
      })
      .pipe(
        tap({
          next: (res: any) => {
            const employee = res['DATA'].employee;

            this.setToken('access_token', res['DATA'].accessToken);
            this.setToken('refresh_token', res['DATA'].refreshToken);
            this.setCurrentEmployee(employee);

            if (employee.firstLogin) {
              this.router.navigateByUrl('/profile');
            } else {
              this.router.navigateByUrl(this.redirectUrl || '/');
            }

            this.redirectUrl = null;
          },
          error: (err: any) => throwError(() => new Error(err)),
        }),
      );
  }

  public isTokenValid(token: string | null): boolean {
    if (!token) return false;

    try {
      const tokenPayload = this.decodeJwt(token);
      if (!tokenPayload || !tokenPayload.exp) return false;

      const currentTime = Math.floor(Date.now() / 1000);
      console.log(tokenPayload.exp > currentTime);

      return tokenPayload.exp > currentTime;
    } catch (error) {
      console.error('Fehler beim Token-Parsing:', error);
      return false;
    }
  }

  private decodeJwt(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Ungültiges JWT-Format:', error);
      return null;
    }
  }

  public getAccessToken(): string | null {
    return localStorage.getItem('access_token');
  }

  public setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  // Logges out employee and removes data from local storage
  public logout(): void {
    localStorage.clear();
    this.router.navigateByUrl('/login');
    this.toastr.success('Logged out successfully');
  }

  public setCurrentEmployee(employee: IEmployee) {
    localStorage.setItem('employee', JSON.stringify(employee));
  }

  /* Sets new token */
  private setToken(type: string, token: string) {
    localStorage.setItem(type, token);
  }

  /* Gets stored authToken for future requests */
  public getRefreshToken(): string | null {
    return localStorage.getItem('refresh_token');
  }

  /**
   * Refreshes token after old one expires */
  public refreshToken(): Observable<any> {
    const body = {
      employee: this.getCurrentEmployee(),
      refreshToken: this.getRefreshToken(),
    };
    return this.http.post(`${environment.baseUrl}/auth/refreshToken`, body).pipe(
      tap((res: any) => {
        this.setToken('access_token', res.DATA.accessToken);
        this.setToken('refresh_token', res.DATA.refreshToken);
      }),
    );
  }

  public getCurrentEmployee(): IEmployee {
    const employee = localStorage.getItem('employee');
    return employee ? JSON.parse(employee) : null;
  }

  /**
   * Changes password of user
   * @param {string} oldPassword - Current password of employee.
   * @param {string} newPassword - New password of employee.
   */
  public changePassword(oldPassword: string, newPassword: string) {
    const employeeID = this.getCurrentEmployee()._id;
    return this.http.put(environment.baseUrl + `/employee/changePassword/${employeeID}`, {
      id: employeeID,
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }

  public setFirstLoginStatus(employee: IEmployee) {
    return this.http.put(environment.baseUrl + `/employee/${employee._id}`, {
      firstname: employee.firstname,
      email: employee.email,
      isAdmin: employee.isAdmin,
      firstLogin: false,
    });
  }
}
