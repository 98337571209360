import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISortOption } from '../models/sort.model';
import { AuthService } from './auth.service';
import { IAiTool, IAiToolUseCase } from '../models/ai-tool.model';

@Injectable({
  providedIn: 'root',
})
export class AiToolUseCaseService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  public getAllUseCases(filterOptions: ISortOption, page: number = 0, pageSize: number = 1000): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString());

    params = params.set('sortBy', filterOptions.sortBy);

    if (filterOptions.searchParam) {
      params = params.set('search', filterOptions.searchParam);
    }

    return this.http.get(environment.baseUrl + '/toolUseCase', { params });
  }

  public createToolUseCase(useCase: IAiToolUseCase): Observable<any> {
    return this.http.post(environment.baseUrl + '/toolUseCase', useCase);
  }

  public updateToolUseCase(useCase: IAiToolUseCase): Observable<any> {
    return this.http.put(`${environment.baseUrl}/toolUseCase/${useCase._id}`, useCase);
  }

  public createComment(useCaseId: string, text: string): Observable<any> {
    return this.http.post(`${environment.baseUrl}/toolUseCase/comment/${useCaseId}`, {
      comment: text,
    });
  }

  public voteUseCase(useCaseId: string, vote: number): Observable<any> {
    const userId = this.authService.getCurrentEmployee()._id;
    return this.http.put(environment.baseUrl + `/toolUseCase/vote`, {
      id: useCaseId,
      voteCount: vote,
      userid: userId,
    });
  }

  public deleteToolUseCase(useCaseId: string): Observable<any> {
    return this.http.delete(environment.baseUrl + `/toolUseCase/${useCaseId}`);
  }

  public deleteComment(useCaseId: string, commentId: string): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/toolUseCase/comment/${useCaseId}`, {
      body: { commentId },
    });
  }
}
