import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Direkt den Refresh-Token überprüfen
    const refreshToken = this.authService.getRefreshToken();

    if (refreshToken && this.authService.isTokenValid(refreshToken)) {
      // Wenn der Access-Token abgelaufen ist, aber der Refresh-Token noch gültig ist,
      // können wir trotzdem true zurückgeben, da der Interceptor den Token erneuern wird
      return of(true);
    } else {
      // Kein gültiger Refresh-Token vorhanden, Umleitung zur Login-Seite
      this.authService.setRedirectUrl(state.url);
      this.router.navigate(['/login']);
      return of(false);
    }
  }
}
