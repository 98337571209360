export enum EToolCategory {
  DESIGN = 'Design',
  DEVELOPMENT = 'Development',
  CONTENTCREATION = 'Content Creation',
  HR = 'HR',
  MARKETING = 'Marketing',
  DATAANALYTICS = 'Data Analytics',
  PROJECTMANAGEMENT = 'Project Management',
  CUSTOMERSERVICE = 'Customer Service',
  PRODUCTIVITY = 'Productivity',
  OTHER = 'Other',
}

export enum EToolStatus {
  UNASSIGNED = 'Unassigned',
  ASSIGNED = 'Assigned',
  TESTED = 'Tested',
  CLOSED = 'Closed',
  ALL = 'ALL',
}

export enum EToolListType {
  REVIEW = 'Review',
  SUGGESTION = 'Suggestion',
  USECASE = 'Use Case',
}
