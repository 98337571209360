import { Component, ElementRef, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';
import { AiToolService } from 'src/app/services/aiTool.service';
import { IAiTool, IAiToolSuggestion, IAiToolUseCase } from 'src/app/models/ai-tool.model';
import { ToastrService } from 'ngx-toastr';
import { AiToolListComponent } from 'src/app/components/ai-tool-list/ai-tool-list.component';
import { EToolListType } from 'src/app/enum/ai-tool.enum';
import { AiToolUseCaseService } from 'src/app/services/aiToolUseCases.service';

@Component({
  selector: 'app-ai-tools',
  templateUrl: './ai-tools.component.html',
  styleUrl: './ai-tools.component.scss',
})
export class AiToolsComponent {
  @ViewChild('toolReviewListComponent') toolReviewListComponent: AiToolListComponent;
  @ViewChild('toolSuggestionListComponent') toolSuggestionListComponent: AiToolListComponent;
  @ViewChild('toolUseCaseListComponent') toolUseCaseListComponent: AiToolListComponent;
  @ViewChild('reviewAndUseCaseContainer') reviewAndUseCaseContainer: ElementRef;
  @ViewChild('suggestionContainer') suggestionContainer: ElementRef;

  public showAiToolDialog = false;
  public isSuggestionDialog = false;
  private subscription = new Subscription();

  public EToolListType = EToolListType;

  public dialogType: EToolListType;
  private observer!: MutationObserver;

  constructor(
    private aiToolService: AiToolService,
    private aiToolUseCaseService: AiToolUseCaseService,
    private toastr: ToastrService,
  ) {}

  ngAfterViewInit() {
    this.adjustHeight();

    // Überwachung auf Änderungen am Inhalt des ersten Containers
    this.observer = new MutationObserver(() => {
      this.adjustHeight();
    });

    this.observer.observe(this.reviewAndUseCaseContainer.nativeElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    // Event Listener für das Fenster-Resize hinzufügen
    window.addEventListener('resize', this.adjustHeight.bind(this));
  }

  private adjustHeight() {
    const leftHeight = this.reviewAndUseCaseContainer.nativeElement.clientHeight;
    this.suggestionContainer.nativeElement.style.maxHeight = `${leftHeight}px`;
  }

  public onOpenAiToolDialog(dialogType: EToolListType): void {
    this.dialogType = dialogType;
    this.showAiToolDialog = true;
  }

  public createNewToolReview(tool: IAiTool): void {
    this.subscription.add(
      this.aiToolService.createToolReview(tool).subscribe({
        next: (res: any) => {
          this.toolReviewListComponent.getAllToolReviews();

          this.showAiToolDialog = false;
          this.toastr.success('Tool review was successfully added');
        },
        error: () => {
          this.toastr.error('Failed to create a new feedback');
        },
      }),
    );
  }
  public createNewToolSuggestion(tool: IAiToolSuggestion): void {
    this.subscription.add(
      this.aiToolService.createToolSuggestion(tool).subscribe({
        next: (res: any) => {
          this.toolSuggestionListComponent.getAllToolSuggestions();

          this.showAiToolDialog = false;
          this.toastr.success('Tool suggestion was successfully added');
        },
        error: () => {
          this.toastr.error('Failed to create a new feedback');
        },
      }),
    );
  }
  public createNewToolUseCase(useCase: IAiToolUseCase): void {
    this.subscription.add(
      this.aiToolUseCaseService.createToolUseCase(useCase).subscribe({
        next: (res: any) => {
          this.toolUseCaseListComponent.getAllUseCases();

          this.showAiToolDialog = false;
          this.toastr.success('Use case was successfully added');
        },
        error: () => {
          this.toastr.error('Failed to create a new feedback');
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    window.removeEventListener('resize', this.adjustHeight.bind(this));
    this.subscription.unsubscribe();
  }
}
