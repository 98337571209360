import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IAiToolUseCase } from 'src/app/models/ai-tool.model';
import { faCommentDots, faLink, faTag } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { IConfirmData } from 'src/app/models/confirm-data';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AiToolUseCaseService } from 'src/app/services/aiToolUseCases.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-ai-tool-use-case-card',
  templateUrl: './ai-tool-use-case-card.component.html',
  styleUrl: './ai-tool-use-case-card.component.scss',
})
export class AiToolUseCaseCardComponent {
  @ViewChild('commentContainer') private commentContainer!: ElementRef;
  @Input() useCase: IAiToolUseCase;
  @Output() useCaseRemoved = new EventEmitter<IAiToolUseCase>();
  @Output() onEditUseCase = new EventEmitter<IAiToolUseCase>();
  public badgeIcon = faTag;

  public newComment = '';
  public showComments = false;
  public confirmDialog = false;
  private subscription = new Subscription();
  public modalData = {} as IConfirmData;
  public commentIcon = faCommentDots;
  public faLink = faLink;
  public showMore = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private aiToolUseCaseService: AiToolUseCaseService,
    public helperService: HelperService,
  ) {}

  public onEditClick(): void {
    this.onEditUseCase.emit(this.useCase);
  }

  public onKeyUp(event: KeyboardEvent, isAdvantageField = false): void {
    if (event.key !== 'Enter') return;
    this.onCreateComment();
  }

  public voteUseCase(useCase: IAiToolUseCase, voteType: 'up' | 'down'): void {
    const employeeId = this.authService.getCurrentEmployee()?._id;

    const userHasVoted = (this.useCase as IAiToolUseCase).hasVoted.includes(employeeId);
    if (userHasVoted) {
      this.toastr.error('You have already voted');
      return;
    }
    const newCount = voteType === 'up' ? useCase.voteCount + 1 : useCase.voteCount - 1;
    this.subscription.add(
      this.aiToolUseCaseService.voteUseCase(this.useCase._id, newCount).subscribe({
        next: () => {
          (this.useCase as IAiToolUseCase).voteCount = newCount;
          this.toastr.success('Use case was successfully voted');
        },
        error: () => {
          this.toastr.error('Failed to vote on tool');
        },
      }),
    );
  }

  public deleteUseCase(): void {
    this.subscription.add(
      this.aiToolUseCaseService.deleteToolUseCase(this.useCase._id).subscribe({
        next: () => {
          this.useCaseRemoved.emit(this.useCase as IAiToolUseCase);
          this.toastr.success('Use case was deleted successfully');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public toggleComments(): void {
    this.showComments = !this.showComments;
    if (this.showComments) {
      setTimeout(() => {
        this.scrollToBottom();
      });
    }
  }

  private scrollToBottom(): void {
    const container = this.commentContainer.nativeElement;
    container.scrollTop = container.scrollHeight;
  }

  public onCreateComment(): void {
    this.subscription.add(
      this.aiToolUseCaseService.createComment(this.useCase._id, this.newComment).subscribe({
        next: (res) => {
          (this.useCase as IAiToolUseCase).comments = res.DATA.comments;
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
        complete: () => {
          this.newComment = '';
        },
      }),
    );
  }

  public deleteComment(commentId: string): void {
    this.subscription.add(
      this.aiToolUseCaseService.deleteComment(this.useCase._id, commentId).subscribe({
        next: () => {
          (this.useCase as IAiToolUseCase).comments = (this.useCase as IAiToolUseCase).comments.filter(
            (comment) => comment._id !== commentId,
          );
          this.toastr.success('Comment deleted successfully');
        },
        error: () => {
          this.toastr.error('Something went wrong');
        },
      }),
    );
  }

  public createRemoveModal(): void {
    this.confirmDialog = true;
    this.modalData.headline = `Delete Use Case?`;
    this.modalData.text = `Are you sure yout want to delete this use case permanently?`;
    this.modalData.image = 'delete';
  }

  public showCloseButton(): boolean {
    return this.authService.getCurrentEmployee().isAdmin && !this.router.url.includes('/dashboard');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
