import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = this.authService.getAccessToken();

    return next.handle(this.addToken(accessToken, req)).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          // Fall 1: Token ist abgelaufen (401)
          if (err.status === 401) {
            return this.handle401Error(req, next);
          }
          // Fall 2: Refresh Token ungültig oder abgelaufen (403)
          else if (err.status === 403) {
            this.handle403Error();
          }
        }

        return throwError(() => err);
      }),
    );
  }

  private addToken(accessToken: string | null, req: HttpRequest<any>) {
    if (accessToken) {
      return req.clone({
        setHeaders: { auth: accessToken },
      });
    } else {
      return req;
    }
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // Überprüfen, ob der Refresh-Token gültig ist
      const refreshToken = this.authService.getRefreshToken();
      if (!refreshToken || !this.authService.isTokenValid(refreshToken)) {
        this.isRefreshing = false;
        this.handle403Error();
        return throwError(() => new Error('Invalid refresh token'));
      }

      return this.authService.refreshToken().pipe(
        switchMap((res) => {
          const token = res?.DATA?.accessToken;
          if (!token) {
            this.handle403Error();
            return throwError(() => new Error('Invalid refresh token'));
          }
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addToken(token, req));
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.handle403Error();
          return throwError(() => err);
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token !== null),
        take(1),
        switchMap((token) => next.handle(this.addToken(token, req))),
      );
    }
  }

  private handle403Error(): void {
    if (!this.isRefreshing) {
      const currentUrl = this.router.url;
      this.authService.setRedirectUrl(currentUrl);
      this.authService.logout();
    }
  }
}
