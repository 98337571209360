import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { faTag } from '@fortawesome/free-solid-svg-icons';
import { IAiTool, IAiToolSuggestion, IAiToolUseCase } from 'src/app/models/ai-tool.model';
import { EToolCategory, EToolListType } from 'src/app/enum/ai-tool.enum';

@Component({
  selector: 'app-ai-tool-dialog',
  templateUrl: './ai-tool-dialog.component.html',
  styleUrl: './ai-tool-dialog.component.scss',
})
export class AiToolDialogComponent {
  @Input() tool?: IAiTool;
  @Input() useCase?: IAiToolUseCase;
  @Input() dialogType = EToolListType.REVIEW;
  @Input() editingMode = false;

  @Output() onCloseDialog = new EventEmitter<boolean>();
  @Output() onReviewFormSubmit = new EventEmitter<IAiTool>();
  @Output() onSuggestionFormSubmit = new EventEmitter<IAiToolSuggestion>();
  @Output() onUseCaseFormSubmit = new EventEmitter<IAiToolUseCase>();

  public badgeIcon = faTag;
  public newAdvantageControl = new FormControl('', Validators.required);
  public newDisadvantageControl = new FormControl('', Validators.required);
  public toolReviewForm: FormGroup;
  public toolSuggestionForm: FormGroup;
  public toolUseCaseForm: FormGroup;
  public newAdvantage = '';
  public newDisadvantage = '';
  public categories = Object.values(EToolCategory);
  public EToolListType = EToolListType;

  constructor(private fb: FormBuilder) {
    this.toolReviewForm = this.fb.group({
      toolName: ['', Validators.required],
      description: ['', Validators.required],
      website: ['', [Validators.required, Validators.pattern('https?://.+')]],
      fileUrl: ['', Validators.pattern('https?://.+')],
      advantages: this.fb.array([], this.minOneItemValidator()),
      disadvantages: this.fb.array([], this.minOneItemValidator()),
      conclusion: ['', Validators.required],
      category: ['', Validators.required],
    });
    this.toolSuggestionForm = this.fb.group({
      toolName: ['', Validators.required],
      description: ['', Validators.required],
      website: ['', [Validators.required, Validators.pattern('https?://.+')]],
    });
    this.toolUseCaseForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      website: ['', [Validators.pattern('https?://.+')]],
      fileUrl: ['', Validators.pattern('https?://.+')],
    });
  }

  ngOnInit(): void {
    if (this.editingMode && this.tool) {
      this.toolReviewForm.patchValue({
        toolName: this.tool.toolName,
        description: this.tool.description,
        website: this.tool.website,
        fileUrl: this.tool.fileUrl,
        advantages: this.tool.advantages,
        disadvantages: this.tool.disadvantages,
        conclusion: this.tool.conclusion,
        category: this.tool.category,
      });

      this.advantages.clear();
      this.disadvantages.clear();

      // Patch advantages & disadvantages
      this.tool.advantages.forEach((advantage: string) => {
        this.advantages.push(this.fb.control(advantage, Validators.required));
      });

      this.tool.disadvantages.forEach((disadvantage: string) => {
        this.disadvantages.push(this.fb.control(disadvantage, Validators.required));
      });
    } else if (this.editingMode && this.useCase) {
      this.toolUseCaseForm.patchValue({
        title: this.useCase.title,
        description: this.useCase.description,
        website: this.useCase.website,
        fileUrl: this.useCase.fileUrl,
      });
    }
  }

  get advantages(): FormArray {
    return this.toolReviewForm.get('advantages') as FormArray;
  }

  get disadvantages(): FormArray {
    return this.toolReviewForm.get('disadvantages') as FormArray;
  }

  private minOneItemValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const array = control as FormArray;
      return array && array.length > 0 ? null : { minOneItem: true };
    };
  }

  public addAdvantage(): void {
    if (this.newAdvantage.trim()) {
      this.advantages.push(this.fb.control(this.newAdvantageControl.value, Validators.required));
      this.newAdvantage = '';
    }
  }

  public addDisadvantage(): void {
    if (this.newDisadvantage.trim()) {
      this.disadvantages.push(this.fb.control(this.newDisadvantageControl.value, Validators.required));
      this.newDisadvantage = '';
    }
  }

  public removeAdvantage(index: number): void {
    this.advantages.removeAt(index);
  }

  public removeDisadvantage(index: number): void {
    this.disadvantages.removeAt(index);
  }

  public onSubmitReview(): void {
    this.toolReviewForm.markAllAsTouched();

    if (!this.toolReviewForm.valid) return;

    const formData = this.toolReviewForm.value;
    if (this.editingMode) {
      formData._id = this.tool._id;
    }
    this.onReviewFormSubmit.emit(formData);
  }
  public onSubmitSuggestion(): void {
    this.toolSuggestionForm.markAllAsTouched();

    if (!this.toolSuggestionForm.valid) return;
    const formData = this.toolSuggestionForm.value;
    this.onSuggestionFormSubmit.emit(formData);
  }
  public onSubmitUseCase(): void {
    this.toolUseCaseForm.markAllAsTouched();

    if (!this.toolUseCaseForm.valid) return;
    const formData = this.toolUseCaseForm.value;
    if (this.editingMode) {
      formData._id = this.useCase._id;
    }

    this.onUseCaseFormSubmit.emit(formData);
  }

  public onKeyUp(event: KeyboardEvent, isAdvantageField = false): void {
    if (event.key !== 'Enter') return;
    if (isAdvantageField) {
      this.addAdvantage();
    } else {
      this.addDisadvantage();
    }
  }

  public onTagSelected(tag: string): void {
    this.toolReviewForm.get('category').setValue(tag);
  }
}
