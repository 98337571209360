import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() amountOpenRequests: number;

  public isPhoneSize: boolean;
  constructor(
    public router: Router,
    private authService: AuthService,
    public helperService: HelperService,
  ) {
    this.observePhoneSize();
  }

  public scrollToSuggestion() {
    const suggestionContainer = document.getElementById('suggestionContainer');

    if (suggestionContainer) {
      suggestionContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
    this.isPhoneSize ? this.helperService.setSidebarVisibility(false) : this.helperService.setSidebarVisibility(true);
  }

  private observePhoneSize(): void {
    this.helperService.isPhoneSize$.subscribe((isPhoneSize) => {
      this.isPhoneSize = isPhoneSize;
    });
  }

  public hideSidebar(): void {
    this.helperService.setSidebarVisibility(false);
  }

  public isCurrentUserAdmin(): boolean {
    return this.authService.getCurrentEmployee()?.isAdmin;
  }
}
