import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { EmployeeRole } from 'src/app/enum/employeeRole.enum';
import { EClothingSize, EShoeSize } from 'src/app/enum/size.enum';
import { IConfirmData } from 'src/app/models/confirm-data';
import { IEmployee } from 'src/app/models/employee.model';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-manage-employee-dialog',
  templateUrl: './manage-employee-dialog.component.html',
  styleUrls: ['./manage-employee-dialog.component.scss'],
})
export class ManageEmployeeDialogComponent {
  @ViewChild('shoeSizeDropdown') shoeSizeDropdown;
  @ViewChild('clothingSizeDropdown') clothingSizeDropdown;
  @ViewChild('employeeRoleDropdown') employeeRoleDropdown;
  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() employeeData = new EventEmitter<IConfirmData>();

  @Input() data: IConfirmData;
  @Input() type: string;

  public employee = {} as IEmployee;

  public employeeRoles = Object.values(EmployeeRole);
  public clothingSizes = Object.values(EClothingSize);
  public shoeSizes = Object.values(EShoeSize);
  public birthday: string;
  public firstDay: string;
  public employeesOvertime: string;
  public timeForm: FormGroup;
  public EmployeeRole = EmployeeRole;

  public newPassword: string;
  public newPasswordConfirmation: string;

  public environment = environment;

  constructor(
    private toastr: ToastrService,
    public helperService: HelperService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    if (this.data.employee) {
      this.employee = { ...this.data.employee };
      this.setEmploeesDates();
    } else {
      this.employee.isArchived = false;
      this.employee.countOvertime = false;
      this.employee.isAdmin = false;
    }

    if (this.data.employee) this.employeesOvertime = this.helperService.formatMinutes(this.employee.overtimeBalance);

    this.timeForm = this.fb.group({
      timeInput: [
        this.employeesOvertime,
        [Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3])(:[0-5][0-9])?$/)],
      ],
    });
  }

  public onSelectBirthday(event: Event) {
    this.birthday = this.helperService.dateToISOString(event);
  }

  public onSelectFirstDay(event: Event) {
    this.firstDay = this.helperService.dateToISOString(event);
  }

  public onSubmit(): void | ActiveToast<any> {
    if (this.isRequiredFieldsFilled()) {
      this.employee.birthdate = new Date(this.birthday);
      this.employee.joinedCompany = new Date(this.firstDay);
      this.data.employee = this.employee;
      this.data.employee.overtimeBalance = this.helperService.calculateMinutes(this.timeForm.get('timeInput').value);
      if (this.employee.role === EmployeeRole.TESTER) {
        this.employee.clockifyApiKey = '';
      }
      this.employeeData.emit(this.data);
      this.closeDialog.emit(true);
    } else {
      return this.toastr.error('Please fill in all required fields: full name, email, role, and joined company date.');
    }
  }

  public getEmployeeRoles(): EmployeeRole[] {
    return Object.values(EmployeeRole).filter((role) => {
      if (!environment.showTester) {
        return role !== EmployeeRole.TESTER;
      }
      return true;
    }) as EmployeeRole[];
  }

  public closeAllDropdowns(dropdown: string) {
    if (dropdown !== 'shoeSizeDropdown' && this.shoeSizeDropdown) {
      this.shoeSizeDropdown.showDropdown = false;
    }
    if (dropdown !== 'clothingSizeDropdown' && this.clothingSizeDropdown) {
      this.clothingSizeDropdown.showDropdown = false;
    }
    if (dropdown !== 'employeeRoleDropdown' && this.employeeRoleDropdown) {
      this.employeeRoleDropdown.showDropdown = false;
    }
  }

  private setEmploeesDates(): void {
    if (this.employee.birthdate) {
      this.birthday = this.formatDateForInput(this.employee.birthdate);
    }
    if (this.employee.joinedCompany) {
      this.firstDay = this.formatDateForInput(this.employee.joinedCompany);
    }
  }

  private formatDateForInput(date: Date | string): string {
    if (!date) return '';
    let dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) return '';
    let month = '' + (dateObj.getMonth() + 1),
      day = '' + dateObj.getDate(),
      year = dateObj.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  private isRequiredFieldsFilled(): string {
    return (
      this.employee.firstname && this.employee.lastname && this.employee.email && this.employee.role && this.firstDay
    );
  }

  public getRoleValue(roleKey: string): string {
    return EmployeeRole[roleKey];
  }

  public onSelectEmployeeRole(role: EmployeeRole) {
    this.employee.role = role;
  }

  public onSelectClothingSize(size: EClothingSize) {
    this.employee.clothingSize = size;
  }

  public onSelectShoeSize(size: EShoeSize) {
    this.employee.shoeSize = size;
  }

  public onClose(): void {
    this.closeDialog.emit(true);
  }
}
