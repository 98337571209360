import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ISortOption } from '../models/sort.model';
import { AuthService } from './auth.service';
import { IAiTool, IAiToolSuggestion } from '../models/ai-tool.model';
import { EToolStatus } from '../enum/ai-tool.enum';

@Injectable({
  providedIn: 'root',
})
export class AiToolService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  public getAllToolReviews(filterOptions: ISortOption, page: number = 0, pageSize: number = 1000): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString());

    params = params.set('sortBy', filterOptions.sortBy);
    params = params.set('sortOrder', filterOptions.sortOrder);
    params = params.set('status', filterOptions.filterBy);
    if (filterOptions.searchParam) {
      params = params.set('search', filterOptions.searchParam);
    }

    return this.http.get(environment.baseUrl + '/tool', { params });
  }
  public getAllToolSuggestions(
    status: EToolStatus = EToolStatus.UNASSIGNED,
    searchParam: string = '',
    page: number = 0,
    pageSize: number = 1000,
  ): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('pageSize', pageSize.toString());

    if (status !== EToolStatus.ALL) {
      params = params.set('status', status);
    }

    if (searchParam) {
      params = params.set('search', searchParam);
    }

    return this.http.get(environment.baseUrl + '/toolSuggestion', { params });
  }

  public createToolReview(tool: IAiTool): Observable<any> {
    return this.http.post(environment.baseUrl + '/tool', tool);
  }

  public createToolSuggestion(tool: IAiToolSuggestion): Observable<any> {
    return this.http.post(environment.baseUrl + '/toolSuggestion', tool);
  }

  public updateToolReview(tool: IAiTool): Observable<any> {
    return this.http.put(`${environment.baseUrl}/tool/${tool._id}`, tool);
  }

  public createComment(toolId: string, text: string): Observable<any> {
    return this.http.post(`${environment.baseUrl}/tool/comment/${toolId}`, {
      comment: text,
    });
  }

  public closeToolCard(toolId: string): Observable<any> {
    return this.http.put(environment.baseUrl + `/tool/closed/${toolId}`, {});
  }

  public voteTool(toolId: string, vote: number): Observable<any> {
    const userId = this.authService.getCurrentEmployee()._id;
    return this.http.put(environment.baseUrl + '/tool', {
      id: toolId,
      voteCount: vote,
      userid: userId,
    });
  }

  public deleteToolReview(toolId: string): Observable<any> {
    return this.http.delete(environment.baseUrl + `/tool/${toolId}`);
  }

  public deleteToolSuggestion(toolId: string): Observable<any> {
    return this.http.delete(environment.baseUrl + `/toolSuggestion/${toolId}`);
  }

  public assignToolSuggestion(toolId: string, assigneeId: string): Observable<any> {
    return this.http.put(`${environment.baseUrl}/toolSuggestion/assignee/${toolId}`, { assigneeId });
  }

  public unassignToolSuggestion(toolId: string): Observable<any> {
    return this.http.put(`${environment.baseUrl}/toolSuggestion/unassign/${toolId}`, {});
  }

  public deleteComment(toolId: string, commentId: string): Observable<any> {
    return this.http.delete(`${environment.baseUrl}/tool/comment/${toolId}`, {
      body: { commentId },
    });
  }
}
