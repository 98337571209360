<div data-cy="use-case-card" class="flex flex-col">
  <div class="mb-1 flex w-full flex-col items-center pt-5">
    @if (authService.getCurrentEmployee().isAdmin) {
      <button
        data-cy="use-case-card-remove-button"
        (click)="createRemoveModal()"
        class="z-10 -mb-4 flex bg-lightGrayish"
      >
        <img data-cy="trash-icon" src="../../../assets/img/icons/delete.png" class="h-7" alt="delete icon" />
      </button>
    }
    <div
      class="relative flex w-full flex-col border-2 border-sunflowerGold bg-paleOrange p-3 max-lg:pt-5 max-lg:text-xs lg:p-5 lg:px-8"
      data-cy="use-case-item"
    >
      <div class="mb-4 mt-10 flex justify-center text-center">
        <span class="text-lg" data-cy="use-case-title">{{ useCase.title }}</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="text-sm font-semibold">Description</span>
        <span class="text-xs" data-cy="use-case-description">{{ useCase.description }}</span>
      </div>

      @if (useCase.website) {
        <div class="mt-4 flex w-60 flex-col gap-1">
          <span class="text-sm font-semibold">Website</span>
          <a
            href="{{ useCase.website }}"
            target="_blank"
            class="max-w-[14rem] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-xs underline"
            data-cy="use-case-website"
          >
            {{ useCase.website }}
          </a>
        </div>
      }

      <div class="mt-5 flex w-full items-center justify-center gap-1 max-sm:relative max-sm:flex-col-reverse">
        <div class="absolute left-3 top-6 flex items-center gap-2 text-sm max-sm:hidden lg:left-8">
          <span class="font-semibold">Author:</span>
          <span class="">{{ useCase.createdBy.firstname + ' ' + useCase.createdBy.lastname }}</span>
        </div>

        <button
          (click)="toggleComments()"
          class="absolute left-8 flex items-center gap-2"
          data-cy="toggle-comments-button"
        >
          <fa-icon class="lg:scale-125" [icon]="commentIcon"></fa-icon>
          <span class="text-xs lg:text-base"> {{ useCase.comments.length }}</span>
        </button>

        <div class="flex items-center">
          <div class="flex items-center gap-2">
            <button (click)="voteUseCase(useCase, 'down')" data-cy="downvote-button">
              <img class="h-7 rotate-180" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
            </button>

            <p class="font-bold lg:text-2xl" data-cy="vote-count">{{ useCase.voteCount }}</p>

            <button (click)="voteUseCase(useCase, 'up')" data-cy="upvote-button">
              <img class="h-7" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
            </button>
          </div>
        </div>
        @if (useCase.fileUrl) {
          <a class="absolute bottom-0 right-4 md:bottom-4" [href]="useCase.fileUrl" target="_blank">
            <fa-icon
              tooltip="Open documents"
              [showDelay]="250"
              class="absolute bottom-2 right-0 flex cursor-pointer items-center lg:right-2 lg:scale-125"
              data-cy="use-case-documents-link"
              [icon]="faLink"
            ></fa-icon>
          </a>
        }
      </div>

      @if (authService.getCurrentEmployee().isAdmin || useCase.createdBy._id === authService.getCurrentEmployee()._id) {
        <button (click)="onEditClick()" class="absolute right-4 top-4 h-4" data-cy="edit-use-case-button">
          <img src="../../../assets/img/icons/edit.png" class="h-5" alt="edit icon" />
        </button>
      }
    </div>
    @if (showComments) {
      <div
        #commentContainer
        class="max-h-80 w-full overflow-y-auto border-x-2 border-b-2 border-sunflowerGold bg-paleOrange"
        data-cy="use-case-comments-list"
      >
        @for (comment of useCase.comments; track comment; let last = $last) {
          <div
            class="relative mx-4 flex flex-col justify-center gap-2 bg-paleOrange py-2 text-xs"
            data-cy="use-case-single-comment"
            [ngClass]="{ 'border-b border-white': !last }"
          >
            <div class="mr-2 flex gap-2 text-sunflowerGold">
              <span> {{ comment.commentedAt | date: 'dd.MM' }}</span>
              <span>-</span>
              <span>{{ comment.commentedBy.firstname }} {{ comment.commentedBy.lastname }}</span>
            </div>
            <span class="mr-8 flex-1 break-words">{{ comment.comment }}</span>
            @if (comment.commentedBy._id === authService.getCurrentEmployee()._id) {
              <img
                data-cy="comment-delete-button"
                (click)="deleteComment(comment._id)"
                src="assets/img/icons/delete.png"
                class="absolute right-2 h-4"
                alt="delete icon"
              />
            }
          </div>
        }

        <div class="relative flex items-center bg-paleOrange">
          <input
            class="no-focus h-10 w-full resize-none p-2 text-xs outline-none placeholder:text-xs"
            data-cy="comment-input"
            placeholder="Write a comment..."
            (keyup)="onKeyUp($event)"
            [(ngModel)]="newComment"
          />

          <button
            class="flex h-10 w-12 items-center justify-center bg-white p-1"
            (click)="onCreateComment()"
            data-cy="use-case-create-comment-button"
          >
            <img class="h-4" src="../../../assets/img/icons/send.png" alt="send" />
          </button>
        </div>
      </div>
    }
  </div>

  @if (confirmDialog) {
    <app-confirm-dialog
      (closeDialog)="confirmDialog = false"
      (saveChanges)="deleteUseCase()"
      [data]="modalData"
    ></app-confirm-dialog>
  }
</div>
