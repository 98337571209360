<div data-cy="tool-card" class="flex flex-col">
  <div class="mb-1 flex w-full flex-col items-center pt-5">
    @if (authService.getCurrentEmployee().isAdmin) {
      <button data-cy="tool-card-remove-button" (click)="createRemoveModal()" class="z-10 -mb-4 flex bg-lightGrayish">
        @if (tool.status === EToolStatus.CLOSED) {
          <img data-cy="trash-icon" src="../../../assets/img/icons/delete.png" class="h-7" alt="delete icon" />
        } @else {
          <img src="../../../assets/img/icons/solve.png" class="h-8" alt="solve" />
        }
      </button>
    }
    <div
      class="relative flex w-full flex-col border-2 border-sunflowerGold bg-paleOrange p-3 max-lg:pt-5 max-lg:text-xs lg:p-5 lg:px-8"
      data-cy="tool-item"
    >
      <div class="flex flex-col" data-cy="tool-card-content" [ngClass]="{ 'mb-2 gap-6': showMore }">
        <div class="mt-6 flex justify-center">
          <span class="text-lg" data-cy="tool-title">{{ tool.toolName }}</span>
        </div>

        <div class="flex flex-col gap-1">
          <span class="text-sm font-semibold">Description</span>
          <span class="text-xs" data-cy="tool-description">{{ tool.description }}</span>
        </div>
        @if (showMore) {
          <div class="flex flex-col gap-6">
            <div class="flex gap-6 max-md:flex-col">
              <div class="flex w-60 flex-col gap-1">
                <span class="text-sm font-semibold">Advantages</span>

                <ol class="list-inside pl-4" data-cy="tool-advantages">
                  @for (advantage of tool.advantages; track advantage) {
                    <li class="text-xs" data-cy="tool-advantage">• {{ advantage }}</li>
                  }
                </ol>
              </div>
              <div class="flex w-60 flex-col gap-1">
                <span class="text-sm font-semibold">Disadvantages</span>
                <ol class="list-inside pl-4" data-cy="tool-disadvantages">
                  @for (disadvantage of tool.disadvantages; track disadvantage) {
                    <li class="text-xs" data-cy="tool-disadvantage">• {{ disadvantage }}</li>
                  }
                </ol>
              </div>
            </div>
            <div class="flex gap-6">
              <div class="flex w-60 flex-col gap-1">
                <span class="text-sm font-semibold">Website</span>
                <a
                  href="{{ tool.website }}"
                  target="_blank"
                  class="max-w-[14rem] cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap text-xs underline"
                  data-cy="tool-website"
                >
                  {{ tool.website }}
                </a>
              </div>

              <div class="flex flex-col gap-1 sm:hidden">
                <span class="text-sm font-semibold">Author</span>
                <span class="text-xs">
                  {{ tool.createdBy.firstname + ' ' + tool.createdBy.lastname }}
                </span>
              </div>
            </div>
            <div class="flex flex-1 flex-col gap-1">
              <span class="text-sm font-semibold">Conclusion</span>
              <span class="text-xs">
                {{ tool.conclusion }}
              </span>
            </div>
          </div>
        }
        <div class="flex w-full items-center justify-center">
          <button class="mt-4 flex items-center gap-2 text-sm text-sunflowerGold" (click)="showMore = !showMore">
            {{ showMore ? 'show less' : 'show more ...' }}
          </button>
        </div>
      </div>
      <div class="mt-5 flex w-full items-center justify-center gap-1 max-sm:relative max-sm:flex-col-reverse">
        <div class="absolute left-3 top-6 flex items-center gap-2 text-sm max-sm:hidden lg:left-8">
          <span class="font-semibold">Author:</span>
          <span class="">{{ tool.createdBy.firstname + ' ' + tool.createdBy.lastname }}</span>
        </div>
        <div class="left-5 flex items-center gap-4 max-md:w-full md:absolute lg:left-8">
          <div class="flex cursor-pointer gap-2 rounded-sm border bg-sunflowerGold px-2 py-1 text-xs text-white">
            <fa-icon [icon]="badgeIcon" class="text-white"></fa-icon>

            <span>{{ tool.category }}</span>
          </div>

          <button (click)="toggleComments()" class="flex items-center gap-2" data-cy="toggle-comments-button">
            <fa-icon class="lg:scale-125" [icon]="commentIcon"></fa-icon>
            <span class="text-xs lg:text-base"> {{ tool.comments.length }}</span>
          </button>
        </div>

        <div class="flex items-center">
          <div class="flex items-center gap-2">
            @if (tool.status != EToolStatus.CLOSED) {
              <button (click)="voteTool(tool, 'down')" data-cy="downvote-button">
                <img class="h-7 rotate-180" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
              </button>
            }

            <p class="font-bold lg:text-2xl" data-cy="vote-count">{{ tool.voteCount }}</p>
            @if (tool.status != EToolStatus.CLOSED) {
              <button (click)="voteTool(tool, 'up')" data-cy="upvote-button">
                <img class="h-7" src="../../../assets/img/icons/arrow-up.png" alt="Arrow up" />
              </button>
            }
          </div>
        </div>

        @if (tool.fileUrl) {
          <a class="absolute bottom-0 right-4 md:bottom-4" [href]="tool.fileUrl" target="_blank">
            <fa-icon
              tooltip="Open documents"
              [showDelay]="250"
              class="flex cursor-pointer items-center lg:right-2 lg:scale-125"
              data-cy="tool-documents-link"
              [icon]="faLink"
            ></fa-icon>
          </a>
        }
      </div>
      <div class="absolute right-4 flex gap-2">
        @if (authService.getCurrentEmployee().isAdmin || tool.createdBy._id === authService.getCurrentEmployee()._id) {
          <button (click)="onEditClick()" data-cy="edit-tool-button">
            <img src="../../../assets/img/icons/edit.png" class="h-5" alt="edit icon" />
          </button>
        }
      </div>
    </div>
    @if (showComments) {
      <div
        #commentContainer
        class="max-h-80 w-full overflow-y-auto border-x-2 border-b-2 border-sunflowerGold bg-paleOrange"
        data-cy="tool-comments-list"
      >
        @for (comment of tool.comments; track comment; let last = $last) {
          <div
            class="relative mx-4 flex flex-col justify-center gap-2 bg-paleOrange py-2 text-xs"
            data-cy="tool-single-comment"
            [ngClass]="{ 'border-b border-white': !last }"
          >
            <div class="mr-2 flex gap-2 text-sunflowerGold">
              <span> {{ comment.commentedAt | date: 'dd.MM' }}</span>
              <span>-</span>
              <span>{{ comment.commentedBy.firstname }} {{ comment.commentedBy.lastname }}</span>
            </div>
            <span class="mr-8 flex-1 break-words">{{ comment.comment }}</span>
            @if (comment.commentedBy._id === authService.getCurrentEmployee()._id) {
              <img
                data-cy="comment-delete-button"
                (click)="deleteComment(comment._id)"
                src="assets/img/icons/delete.png"
                class="absolute right-2 h-4"
                alt="delete icon"
              />
            }
          </div>
        }

        <div class="relative flex items-center bg-paleOrange">
          <input
            class="no-focus h-10 w-full resize-none p-2 text-xs outline-none placeholder:text-xs"
            data-cy="comment-input"
            placeholder="Write a comment..."
            (keyup)="onKeyUp($event)"
            [(ngModel)]="newComment"
          />

          <button
            class="flex h-10 w-12 items-center justify-center bg-white p-1"
            (click)="onCreateComment()"
            data-cy="tool-create-comment-button"
          >
            <img class="h-4" src="../../../assets/img/icons/send.png" alt="send" />
          </button>
        </div>
      </div>
    }
  </div>

  @if (confirmDialog) {
    <app-confirm-dialog
      (closeDialog)="confirmDialog = false"
      (saveChanges)="onDelteModalConfirmation()"
      [data]="modalData"
    ></app-confirm-dialog>
  }
</div>
