<div class="flex w-full flex-col items-center justify-center gap-6">
  <div class="flex w-full max-w-7xl flex-col gap-5">
    <div class="flex flex-col gap-3 bg-lightGrayish p-5 lg:gap-5">
      <app-text-content-box [headline]="'Info'" [headerIcon]="'ℹ️'"></app-text-content-box>
      <p class="flex flex-col text-xs lg:text-base">
        <span class="mb-3">
          Choose tools from the “To be Tested” list, assign them to you, test them extensively and then create a
          feedback report. <br />You can also <strong>vote on reports</strong> afterwards and leave other opinions or
          additions in the comments below (but be nice! ;)).</span
        >
        <span class="mb-3">
          ALSO: You get <strong>rewarded with 42-Coins</strong>, once a tool gets applied permanently. Feel free to
          <strong>extend the tool list</strong> (each tested tool MUST be included in both the reports and the testing
          list to avoid duplicates). For more inspiration you can have a look at the following pages:</span
        >
        <span>
          <a class="text-sunflowerGold" href="https://www.futurepedia.io" target="_blank">futurepedia.io</a>
          | <a class="text-sunflowerGold" href="https://agent.ai" target="_blank">agent.ai </a> |
          <a class="text-sunflowerGold" href="https://theresanaiforthat.com" target="_blank"> theresanaiforthat.com </a>
        </span>
      </p>
    </div>
    <div class="flex max-lg:flex-col lg:gap-4">
      <div #reviewAndUseCaseContainer class="flex h-fit flex-1 flex-col gap-4">
        <div class="flex flex-col bg-lightGrayish p-5 shadow-md max-md:mb-5" data-cy="feedback-container">
          <div class="mb-4 flex items-center justify-between">
            <app-text-content-box class="mb-4" headline="List of Tools" headerIcon="🤖"></app-text-content-box>
            <button
              data-cy="add-review-button"
              (click)="onOpenAiToolDialog(EToolListType.REVIEW)"
              class="flex h-8 w-32 items-center justify-center border border-black bg-almostBlack p-2 text-xs text-sunflowerGold"
            >
              Add Review
            </button>
          </div>
          <app-ai-tool-list [listType]="EToolListType.REVIEW" #toolReviewListComponent></app-ai-tool-list>
        </div>

        <div class="flex flex-col bg-lightGrayish p-5 shadow-md max-md:mb-5" data-cy="useCase-container">
          <div class="mb-4 flex items-center justify-between">
            <app-text-content-box
              class="mb-4"
              headline="Possible AI- and Automation-Usecases"
              headerIcon="🤖"
            ></app-text-content-box>
            <button
              data-cy="add-use-case-button"
              (click)="onOpenAiToolDialog(EToolListType.USECASE)"
              class="flex h-8 w-32 items-center justify-center border border-black bg-almostBlack p-2 text-xs text-sunflowerGold"
            >
              Add Use case
            </button>
          </div>
          <app-ai-tool-list [listType]="EToolListType.USECASE" #toolUseCaseListComponent></app-ai-tool-list>
        </div>
      </div>

      <div
        id="suggestionContainer"
        #suggestionContainer
        class="flex w-full flex-col overflow-auto bg-lightGrayish p-5 shadow-md lg:w-1/3"
        data-cy="feedback-container"
      >
        <div class="mb-6 flex items-center justify-between">
          <app-text-content-box headline="To be Tested" headerIcon="🤖"></app-text-content-box>
          <button
            data-cy="add-suggestion-button"
            (click)="onOpenAiToolDialog(EToolListType.SUGGESTION)"
            class="flex h-8 w-32 items-center justify-center border border-black bg-almostBlack p-2 text-xs text-sunflowerGold"
          >
            Add Suggestion
          </button>
        </div>

        <app-ai-tool-list [listType]="EToolListType.SUGGESTION" #toolSuggestionListComponent></app-ai-tool-list>
      </div>
    </div>
  </div>
</div>
@if (showAiToolDialog) {
  <app-ai-tool-dialog
    #aiToolDialogComponent
    [dialogType]="dialogType"
    (onCloseDialog)="showAiToolDialog = false"
    (onReviewFormSubmit)="createNewToolReview($event)"
    (onSuggestionFormSubmit)="createNewToolSuggestion($event)"
    (onUseCaseFormSubmit)="createNewToolUseCase($event)"
  ></app-ai-tool-dialog>
}
