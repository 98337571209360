<div class="min-w-screen fixed inset-0 left-0 top-0 z-40 flex h-screen w-full items-center justify-center">
  <div class="absolute inset-0 bg-black opacity-30"></div>
  <div class="z-50 mt-16 max-h-[90vh] w-full max-w-3xl overflow-auto bg-white p-8" data-cy="tool-dialog">
    <h2 class="pb-4 text-xl" data-cy="dialog-title">
      {{
        dialogType === EToolListType.SUGGESTION
          ? 'Suggest AI Tool'
          : dialogType === EToolListType.REVIEW
            ? 'Share Your Experience'
            : 'Share a Use Case for AI Tools'
      }}
    </h2>
    @if (dialogType === EToolListType.SUGGESTION) {
      <form [formGroup]="toolSuggestionForm" (ngSubmit)="onSubmitSuggestion()" class="flex flex-col items-center">
        <div class="relative flex w-full flex-col gap-8">
          <!-- Tool Name -->
          <div>
            <input
              formControlName="toolName"
              type="text"
              class="mt-2 w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
              placeholder="Insert name of the tool"
              data-cy="input-tool-name"
            />
            @if (toolSuggestionForm.get('toolName').touched && toolSuggestionForm.get('toolName').invalid) {
              <p class="absolute text-xs text-errorRed">Tool name is required.</p>
            }
          </div>

          <!-- Description -->
          <div class="relative h-12">
            <textarea
              formControlName="description"
              class="h-12 w-full resize-none border p-2 text-xs outline-none placeholder:text-xs placeholder:text-mediumGray"
              placeholder="Describe the tool"
              data-cy="input-tool-description"
            ></textarea>
            @if (toolSuggestionForm.get('description').touched && toolSuggestionForm.get('description').invalid) {
              <p class="absolute text-xs text-errorRed">Description is required.</p>
            }
          </div>

          <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div class="w-full">
              <div class="w-full">
                <input
                  formControlName="website"
                  type="url"
                  class="w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
                  placeholder="Insert Website link"
                  data-cy="input-tool-website"
                />
                @if (toolSuggestionForm.get('website').touched && toolSuggestionForm.get('website').invalid) {
                  <p class="absolute mt-1 text-xs text-errorRed">Website URL is required and must be valid.</p>
                }
              </div>
              <!-- Website -->
            </div>
          </div>

          <div class="flex w-full justify-between max-md:gap-4">
            <button
              (click)="onCloseDialog.emit(false)"
              class="flex items-center border bg-white px-5 py-2 text-sm font-medium tracking-wider text-slateGray shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="flex h-11 w-full items-center justify-center gap-2 border border-black bg-almostBlack p-5 text-xs text-sunflowerGold md:w-48"
              data-cy="submit-tool-button"
            >
              Create Tool Suggestion
            </button>
          </div>
        </div>
      </form>
    } @else if (dialogType === EToolListType.REVIEW) {
      <form [formGroup]="toolReviewForm" (ngSubmit)="onSubmitReview()" class="flex flex-col items-center">
        <div class="relative flex w-full flex-col gap-8">
          <!-- Tool Name -->
          <div>
            <input
              formControlName="toolName"
              type="text"
              class="mt-2 w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
              placeholder="Insert name of the tool"
              data-cy="input-tool-name"
            />
            @if (toolReviewForm.get('toolName').touched && toolReviewForm.get('toolName').invalid) {
              <p class="absolute text-xs text-errorRed">Tool name is required.</p>
            }
          </div>

          <!-- Description -->
          <div class="relative h-12">
            <textarea
              formControlName="description"
              class="h-12 w-full resize-none border p-2 text-xs outline-none placeholder:text-xs placeholder:text-mediumGray"
              placeholder="Describe the tool"
              data-cy="input-tool-description"
            ></textarea>
            @if (toolReviewForm.get('description').touched && toolReviewForm.get('description').invalid) {
              <p class="absolute text-xs text-errorRed">Description is required.</p>
            }
          </div>

          <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div class="w-full">
              <div class="w-full">
                <input
                  formControlName="website"
                  type="url"
                  class="w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
                  placeholder="Insert Website link"
                  data-cy="input-tool-website"
                />
                @if (toolReviewForm.get('website').touched && toolReviewForm.get('website').invalid) {
                  <p class="absolute mt-1 text-xs text-errorRed">Website URL is required and must be valid.</p>
                }
              </div>
              <!-- Website -->
            </div>
            <div class="w-full">
              <!-- File URL (optional) -->
              <input
                formControlName="fileUrl"
                type="url"
                class="w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
                placeholder="Insert file link (optional)"
                data-cy="input-tool-outlook"
              />
              @if (toolReviewForm.get('fileUrl').touched && toolReviewForm.get('fileUrl').invalid) {
                <p class="absolute mt-1 text-xs text-errorRed">File URL must be valid.</p>
              }
            </div>
            <div class="flex w-full flex-col">
              <!-- Advantages -->
              <div class="w-full" data-cy="input-tool-advantages">
                <label class="mb-2 text-xs">Advantages</label>
                <div class="mt-2 flex items-center gap-2">
                  <!-- Input field for adding a new advantage -->
                  <input
                    [(ngModel)]="newAdvantage"
                    [formControl]="newAdvantageControl"
                    type="text"
                    class="w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
                    placeholder="Add a new advantage"
                    (keyup)="onKeyUp($event, true)"
                    data-cy="input-tool-advantage"
                  />
                  <button
                    data-cy="add-advantage-button"
                    type="button"
                    (click)="addAdvantage()"
                    class="w-16 whitespace-nowrap text-xs text-sunflowerGold"
                  >
                    + Add
                  </button>
                </div>
                @if (toolReviewForm.get('advantages').touched && toolReviewForm.get('advantages').invalid) {
                  <p class="absolute mt-1 text-xs text-errorRed">At least one advantage is required.</p>
                }
                <!-- List of added advantages -->
                <div class="flex max-h-40 flex-col overflow-auto">
                  @for (advantage of advantages.controls; track advantage; let i = $index; let last = $last) {
                    <div class="relative mt-2 flex w-[calc(100%-4rem)] items-center gap-2">
                      <div [ngClass]="{ 'border-b': !last }" class="w-5/6 py-2 pl-2 text-xs">
                        • {{ advantage.value }}
                      </div>

                      <img
                        (click)="removeAdvantage(i)"
                        src="../../../assets/img/icons/delete.png"
                        class="absolute right-4 h-4 cursor-pointer"
                        alt="delete icon"
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div class="flex w-full flex-col">
              <!-- Disdvantages -->
              <div class="w-full" data-cy="input-tool-disadvantages">
                <label class="mb-2 text-xs">Disadvantages</label>
                <div class="mt-2 flex items-center gap-2">
                  <!-- Input field for adding a new advantage -->
                  <input
                    [(ngModel)]="newDisadvantage"
                    [formControl]="newDisadvantageControl"
                    type="text"
                    class="w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
                    placeholder="Add a new disadvantage"
                    (keyup)="onKeyUp($event)"
                    data-cy="input-tool-disadvantage"
                  />
                  <button
                    data-cy="add-disadvantage-button"
                    type="button"
                    (click)="addDisadvantage()"
                    class="w-16 whitespace-nowrap text-xs text-sunflowerGold"
                  >
                    + Add
                  </button>
                </div>
                @if (toolReviewForm.get('disadvantages').touched && toolReviewForm.get('disadvantages').invalid) {
                  <p class="absolute mt-1 text-xs text-errorRed">At least one disadvantage is required.</p>
                }
                <!-- List of added advantages -->
                <div class="flex max-h-40 flex-col overflow-auto">
                  @for (disadvantage of disadvantages.controls; track disadvantage; let i = $index; let last = $last) {
                    <div class="relative mt-2 flex w-[calc(100%-4rem)] items-center gap-2">
                      <div [ngClass]="{ 'border-b': !last }" class="w-5/6 py-2 pl-2 text-xs">
                        • {{ disadvantage.value }}
                      </div>

                      <img
                        (click)="removeDisadvantage(i)"
                        src="../../../assets/img/icons/delete.png"
                        class="absolute right-4 h-4 cursor-pointer"
                        alt="delete icon"
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

          <!-- Conclusion -->
          <div class="h-12">
            <textarea
              formControlName="conclusion"
              class="h-12 w-full resize-none border p-2 text-xs outline-none placeholder:text-xs placeholder:text-mediumGray"
              placeholder="What is your conclusion?"
              data-cy="input-tool-summary"
            ></textarea>
            @if (toolReviewForm.get('conclusion').touched && toolReviewForm.get('conclusion').invalid) {
              <p class="absolute text-xs text-errorRed">Conclusion is required.</p>
            }
          </div>

          <!-- Categories -->
          <div>
            <label class="mb-2 text-xs">Categories</label>
            <div data-cy="categories" class="mt-2 flex w-full flex-wrap items-center gap-3 max-lg:justify-center">
              @for (tag of categories; track tag) {
                <div
                  (click)="onTagSelected(tag)"
                  [ngClass]="toolReviewForm.get('category').value === tag ? 'bg-sunflowerGold text-white' : ''"
                  class="flex cursor-pointer gap-2 rounded-sm border border-sunflowerGold px-2 py-1 text-xs"
                >
                  <fa-icon
                    [icon]="badgeIcon"
                    [ngClass]="toolReviewForm.get('category').value === tag ? 'text-white' : 'text-sunflowerGold'"
                  ></fa-icon>

                  <span>{{ tag }}</span>
                </div>
              }
            </div>
          </div>

          <div class="flex w-full justify-between max-md:gap-4">
            <button
              type="button"
              (click)="onCloseDialog.emit(false)"
              class="flex items-center border bg-white px-5 py-2 text-sm font-medium tracking-wider text-slateGray shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="flex h-11 w-full items-center justify-center gap-2 border border-black bg-almostBlack p-5 text-xs text-sunflowerGold md:w-40"
              data-cy="submit-tool-button"
            >
              {{ editingMode ? 'Update Tool Review' : 'Create Tool Review' }}
            </button>
          </div>
        </div>
      </form>
    } @else {
      <form [formGroup]="toolUseCaseForm" (ngSubmit)="onSubmitUseCase()" class="flex flex-col items-center">
        <div class="relative flex w-full flex-col gap-8">
          <!-- Use Case Name -->
          <div>
            <input
              formControlName="title"
              type="text"
              class="mt-2 w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
              placeholder="Insert the title of the Use Case"
              data-cy="input-use-case-title"
            />
            @if (toolUseCaseForm.get('title').touched && toolUseCaseForm.get('title').invalid) {
              <p class="absolute text-xs text-errorRed">Use Case name is required.</p>
            }
          </div>

          <!-- Description -->
          <div class="relative h-12">
            <textarea
              formControlName="description"
              class="h-12 w-full resize-none border p-2 text-xs outline-none placeholder:text-xs placeholder:text-mediumGray"
              placeholder="Describe the Use Case"
              data-cy="input-use-case-description"
            ></textarea>
            @if (toolUseCaseForm.get('description').touched && toolUseCaseForm.get('description').invalid) {
              <p class="absolute text-xs text-errorRed">Description is required.</p>
            }
          </div>

          <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
            <div class="w-full">
              <div class="w-full">
                <input
                  formControlName="website"
                  type="url"
                  class="w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
                  placeholder="Insert Website link (optional)"
                  data-cy="input-use-case-website"
                />
                @if (toolUseCaseForm.get('website').touched && toolUseCaseForm.get('website').invalid) {
                  <p class="absolute mt-1 text-xs text-errorRed">Website URL must be valid.</p>
                }
              </div>
              <!-- Website -->
            </div>
            <div class="w-full">
              <!-- File URL (optional) -->
              <input
                formControlName="fileUrl"
                type="url"
                class="w-full border p-2 text-xs placeholder:text-xs placeholder:text-mediumGray"
                placeholder="Insert file link (optional)"
                data-cy="input-use-case-outlook"
              />
              @if (toolUseCaseForm.get('fileUrl').touched && toolUseCaseForm.get('fileUrl').invalid) {
                <p class="absolute mt-1 text-xs text-errorRed">File URL must be valid.</p>
              }
            </div>
          </div>
          <div class="flex w-full justify-between max-md:gap-4">
            <button
              type="button"
              (click)="onCloseDialog.emit(false)"
              class="flex items-center border bg-white px-5 py-2 text-sm font-medium tracking-wider text-slateGray shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="flex h-11 w-full items-center justify-center gap-2 border border-black bg-almostBlack p-5 text-xs text-sunflowerGold md:w-40"
              data-cy="submit-use-case-button"
            >
              {{ editingMode ? 'Update Use Case' : 'Create Use Case' }}
            </button>
          </div>
        </div>
      </form>
    }
  </div>
</div>
